@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

body {
  background:#f8f8f8;
  font-family: 'Roboto', sans-serif !important;
  color:#333;
  font-size:16px;
  line-height: 1.6;
}

img {
  max-width: 100%;
}

a, a:visited {
  text-decoration:none;
  color:#2196f3;
}

html,
body {
  height: 100%;
}

header {
  padding:5px;
  box-shadow: 0px 0px 5px #ccc;
  background:#fff;
}

h3 {
  color:#444;
  font-weight:300;
  font-size:28px;
  margin-top: 0px;
}

.btn, .btn:visited {
  padding:10px 20px;
  display:inline-block;
  border-radius:5px;
  background:#888;
  color:#fff;
  cursor:pointer;
}

span.manager-label {
  margin-left: 2px;
  font-size: 12px;
  padding: 3px;
  color: #fff;
  border-radius: 3px;
  margin-right: 4px;
  background: #389aca;
}

.btn-primary, .btn-primary:visited {
  background:#2196f3;
}

.btn-primary:disabled, .btn-primary:disabled:hover {
  background:#888;
  cursor:no-drop;
}

.btn-primary:hover {
  background:#64b5f5;
}

.Grid-cell:nth-child(1) .basic-header, .Grid-cell:nth-child(1) .button-submit{
  background:#41a5fb !important;
  color:#fff;
}

.Grid-cell:nth-child(2) .basic-header, .Grid-cell:nth-child(2) .button-submit {
  background:#308ede !important;
  color:#fff;
}

.Grid-cell:nth-child(3) .basic-header, .Grid-cell:nth-child(3) .button-submit {
  background:#136bc3 !important;
  color:#fff;
}

.Grid-cell:nth-child(4) .basic-header, .Grid-cell:nth-child(4) .button-submit {
  background:#0b5bab !important;
  color:#fff;
}

.login-swap {
  font-size:14px;
  margin-top:5px;
}

.app-logo {
  width:80px;
}

.resources {
  line-height:1.6;
}

.welcome-row {
  display: flex;
  flex-direction: row;
}

.welcome-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}

.welcome-title h3 {
  color: #7ed957;
  margin-top: 20px;
  text-align: center;
}

a.download {
    font-size: 16px;
    background: #5fb5ff;
    color: #fff;
    border-radius: 5px;
    float: right;
    padding: 10px 15px;
}

.welcome {
  max-width:90%;
  padding:25px;
  text-align:center;
  background:#fff;
  border:1px solid #eee;
  border-radius: 5px;
  margin:20px auto;
}

.welcome-center {
  text-align: center;
}

.welcome-list {
  text-align: left;
}

.react-pricing-table {
  font-family:Roboto !important;
}

.welcome-login, .welcome-manage-link {
  max-width: 90%;
  padding: 20px !important;
  font-size:18px !important;
}

.welcome-manage-link {
  margin-bottom:0px !important;
  background-color:#7ed957 !important;
  color:#fff !important;
}

.welcome-manage-link:hover {
  background-color:#4ca128 !important;
}

.welcome-logo {
  width:200px;
}

.Grid-cell {
  flex:none;
}

.additional-options table {
  font-size:16px;
  border-collapse: collapse;
  border-top:1px solid #eee;
}

.more-info {
  margin-top:50px;
  font-style:italic;
}

.margin-bt {
  margin-bottom:20px;
}

.additional-options tr {
  border-bottom:1px solid #eee;
}

.additional-options tr td {
  padding:10px;
}

.additional-options tr td:nth-child(1) {
  width:35%;
  font-weight:bold;
}

.additional-options tr td:nth-child(2) {
  padding-left:30px;
} 

.welcome-message {
  font-size:15px;
  line-height:1.7;
}

.welcome-left {
  text-align: left;
}

.welcome-center {
  text-align: center;
}

.Grid-cell:nth-child(1) .price {
  margin-left:0px;
}

.Grid-cell:nth-child(4) .price {
  margin-right:0px;
}

.invites {
  width:33%;
  float:right;
}

.staff-list {
  margin-bottom:20px;
  width:65%;
  padding-right:2%;
  float:left;
}

.staff-list .member {
  margin-bottom:10px;
  white-space: nowrap;
  font-size: 14px;
}

.staff-list .members {
  height:340px;
  overflow-y:scroll;
  padding:15px;
  background:#f9f9f9;
  margin-bottom:20px;
}

a.download-link.send-report {
  background: #2caf14;
}

a.download-link.send-report:hover {
  background:#1d8a09;
}

span.tag.s-ccatemanager {
  background: #2078b7;
}

span.tag.finished {
  background: #4ead42;
}

.tag.scheduled {
  background: #764dd6;
}

.invite-code {
  border:1px solid #ccc;
  border-radius: 3px;
  padding:15px;
}

span.code-warn {
  font-size:12px;
  color:rgb(211, 62, 62);
}

span.tag.inprogress {
  background: #de9715;
}

span.tag.invited {
  background: #00a1ff;
}

span.tag.mgrinvite {
  background:#389aca;
}

span.survey-action.start-survey {
  margin-top: -6px;
  margin-left: 20px;
}

.manual-invite .react-multi-email {
    margin-top: -25px;
    margin-bottom: 10px;
}

.report-table th {
  text-align:left;
}

.report-table .name {
  max-width:280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.report-table {
  width:100%;
}

.report-table th, .report-table td {
  padding:5px;
  padding-right:10px;
}

.survey hr {
  margin-top:10px;
  margin-bottom:10px;
  background:#ddd;
}
.school-switch {
  float:right;
  font-size:14px;
  background:#fff;
  padding:10px 20px;
  border-radius:5px;
  border:1px solid #ccc;
  box-shadow:0px 0px 5px #ccc;
  margin-top:-5px;
  color:#888 !important;
}

.back {
  width:420px;
  margin:-20px auto;
  margin-bottom:30px;
}

.back a {
  color:#888;
}

.school-switch:hover {
  background:#f8f8f8;
}

.remove-member {
  color:#fff;
  padding:3px 5px;
  background:#ff6b6b;
  font-size:10px;
  font-weight:bold;
  border-radius:3px;
  cursor:pointer;
  float:right;
  margin-top: 3px;
}

.invite-others {
  margin-right:10px;
}

.selected-emails {
  margin-bottom:20px;
  height:400px;
  overflow-y:scroll;
  background:#f9f9f9;
  padding:30px;
}

input, select {
  padding:10px;
  border-radius:3px;
  font-size:14px;
  border:1px solid #ccc;
}

.dashboard-data-selection {
  padding:20px;
  text-align:center;
  border-bottom:1px solid #ccc;
  border-top: 1px solid #ccc;
  margin-bottom:30px;
}

.dashboard-data-selection h4 {
  display:inline-block;
  margin-right:10px;
  margin-left:10px;
}

.dashboard-data-selection input, .dashboard-data-selection select {
  margin-right:10px;
  margin-left:10px;
}

.two-col .col {
  width:48%;
}

.login input {
  margin-bottom:5px;
  margin-top:10px;
  width:300px;
}

.login Button {
  margin-top:5px;
}

.login .inner {
  width:340px;
  text-align:center;
  margin:40px auto;
  background:#fff;
  padding:40px;
  border-radius: 5px;
  box-shadow:0px 0px 5px #ccc;
}

.table_percent {
  min-width: 106px;
}

.panel {
  padding:10px;
  border-radius: 5px;
  border:1px solid #eee;
  background:#fff;
  box-sizing: border-box;
}

.login-logo {
  width:200px;
}

.report-gen-form {
  padding:25px;
  border:1px solid #ccc;
  clear: both;
}

.report-gen-form h4 {
  font-size:18px;
  margin-top:0px;
}

.school-compare-select {
  padding-right:25px;
  display:inline-block;
}

.survey-title {
  margin-top: 35px;
  margin-bottom: -55px;
}

.reccs-select {
  margin-left:-12px;
  padding-right:30px;
}

a.download-link {
  float: right;
  color: #fff;
  background: #2196f3;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: -40px;
  margin-left:15px;
}

a.download-link:hover {
  background:#006dc3;
  cursor: pointer;
}

.download-icon {
  margin-left:6px;
  vertical-align: middle;
  margin-top:-5px;
}

.generating-pdf {
  width:100%;
  margin-top:40px;
  text-align:center;
}

.generating-pdf h3 {
  font-weight: normal;
  margin-bottom: 40px;
}

.generating-pdf div {
  margin:0 auto;
}

.graph {
  width:100%;
  height:width+(0.5*width);
}

.popular{
  background-color: rgba(127, 205, 187, 0.3);
}

.improvement_factor{
  background-color: rgba(219, 180, 180, 0.3);
}

.group_0{
  background-color: rgba(167, 157, 157, 0.171);
}

.group_1{
  background-color: rgba(199, 33, 33, 0.3);
}

.group_2{
  background-color: rgba(223, 138, 69, 0.3);
}

.group_3{
  background-color: rgba(137, 209, 55, 0.3);
}

.group_4{
  background-color: rgba(27, 133, 36, 0.3);
}

.half {
  width:49%;
}

.left {
  float:left;
}

.right {
  float:right;
}

.large-num {
  width:25%;
  float:left;
  text-align:center;
}

.large-num {
  border-right:1px solid #eee;
  margin-right:-1px;
}

.large-num .title {
  font-size:18px;
  font-weight: 300;
}

.large-num:nth-child(4) {
  border-right:0px;
  margin-right:0px;
}

.large-num .num {
  font-size:60px;
  padding-top:10px;
  color:#2196f3b3;
}

.clearpad {
  height:15px;
  clear:both;
  width:100%;
}

.clearfix {
  clear:both;
  width:100%;
}

.header-right {
  margin-left:auto;
  margin-right:30px;
  margin-top:5px;
}

.header-right .user-info {
  vertical-align: middle;
  color:#fff;
  font-size:12px;
}

.main {
  max-width: 100%;
  margin-left:340px;
  padding:25px;
  padding-top:75px;
}

.sidebar-menu {
  width: 330px;
  float: left;
  background:#fff url("./img/sidebar-bg.png") no-repeat;
  background-position-y: 150px;
  background-size:200%;
  position: fixed;
  top:55px;
  bottom:0;
  z-index: 500;
  border-right:1px solid #eee;
}

.sidebar-menu .inner {
  padding:15px;
  position: fixed;
  top:55px;
  bottom:0;
  margin-bottom:80px;
  overflow-y:auto;
  width: 300px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.powered-by {
  color:#999;
  text-align:center;
  font-size:12px;
  position:absolute;
  bottom:10px;
  left: 40px;
  width: 240px;
}

.sidebar-menu ul {
  margin:0;
  padding:0;
  margin-top:25px;
}

.sidebar-menu ul li {
  list-style-type:none;
}

.invite-school {
  margin-bottom:10px;
}

.invite-school-title {
  font-weight:bold;
}

.invite-school-user {
  margin-left:10px;
  margin-top:6px;
}

.sidebar-menu ul li a {
  display:block;
  padding:18px 20px;
  padding-top:21px;
  color:#666;
  margin-bottom:10px;
  cursor:pointer;
  text-decoration:none;
}

.sidebar-menu ul li svg {
  vertical-align: middle;
  margin-right:15px;
  margin-top:-3px;
}

.sidebar-menu ul li a.active, .sidebar-menu ul li:hover a {
  background:#2196f3;
  color:#fff;
  border-radius:4px;
}

.hidden {
  display:none;
}

hr {
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #ccc; /* old IE */
  background-color: #ccc; /* Modern Browsers */
}

h4.title {
  font-weight:400;
  margin:10px;
  font-size:20px;
  display:inline-block;
}

.header {
  background:#2196f3;
  color:#f0f0f0;
  padding-left:25px;
  padding-right:25px;
  height:55px;
  position: fixed;
  top:0;
  left:0;
  width:100%;
  z-index:9999;
  box-sizing: border-box;
}

.account-info {
  float:right;
  padding-top:12px;
  text-align:right;
}

.account-info a.btn-primary {
  font-size:14px;
  padding:5px 20px;
  background:#fff;
  color:#2196f3;
}

.error {
  margin-top:20px;
  background:#f35a5a;
  color:#fff;
  padding:10px;
}

.survey {
  font-size: 14px;
}

.question {
  color:#222;
  margin-bottom:20px;
  font-weight:400;
  font-size:16px;
}

.question.level {
  margin-bottom:0px;
}

.question.text {
  margin-top:30px;
}

.question.text input {
  width:400px;
  margin-top:7px;
}

.question.textarea, .question.multiple-choice, .question.multiple-select, .question.page-break {
  margin-top:30px;
}

.question.multiple-choice .mc-option, .question.multiple-select .mc-option {
  padding:8px 10px;
}
.question.page-break {
  background: repeating-linear-gradient(
    45deg,
    #e5f5ff,
    #e5f5ff 6px,
    #fff 6px,
    #fff 16px
  );
  text-align:center;
  padding:20px;
  color:#666;
  font-size:18px;
  font-weight:bold;
}


.question.textarea textarea {
  width:100%;
  height:300px;
  border:1px solid #ccc;
  resize: none;
  border-radius:3px;
  margin-top:7px;
}

.question-text {
  font-weight:700;
}

.level-col {
  display:inline-block;
  width:13%;
  text-align:center;
  vertical-align: middle;
}

.level-col:nth-child(1) {
  width:26%;
  text-align:left;
  padding-left:2%;
}

.level-row {
  border-bottom:1px solid #ccc;
  padding-top:30px;
  padding-bottom:30px;
}

.level-row.head {
  font-weight:bold;
}

.level-col input {
  vertical-align: middle;
  width: 16px;
  height: 16px;
}

.survey-list-item {
  padding:20px;
  border-bottom:1px solid #eee;
  display: block;
}

.survey-list-item:hover {
  background:#f8f8f8;
  cursor:pointer;
}

.survey-list-item:last-child {
  border-bottom:0px;
}

.survey-list-item a, .survey-list-item .survey-action {
  float:right;
  margin-left:12px;
  color:#667;
  cursor:pointer;
}

.view-report-button, .view-school-report-button {
  margin-top: -7px !important;
}

.view-school-report-button, .view-report-button {
  float:right;
}

.school-name {
  color:#666;
}

.breadcrumbs {
  margin-bottom:25px;
  font-size:14px;
}

.breadcrumbs .breadcrumb {
  color:#3a6fb5;
  cursor:pointer;
}

.survey-list-item a:hover, .survey-list-item .survey-action:hover {
  color:#999;
}

.create-survey {
  float:right;
}

.create-survey input {
  margin-right:10px;
}

.surveys .title {
  float: left;
  width: 350px;
  padding-top: 3px;
  margin-bottom: 25px;
}

.large-pad {
  padding:25px;
}

.large-margin-top {
  margin-top:30px;
}

.profile-pic {
  width:150px;
  margin-bottom:20px;
}

.page-nav {
  margin-top:30px;
}

span.tag {
  padding: 3px;
  font-size: 12px;
  background: #666;
  margin: 5px;
  margin-right:2px;
  color: #fff;
  border-radius: 3px;
}

span.tag.administrator {
  background:#0052cc;
}

span.tag.teacher {
  background:#A33CD6;
}

.loading {
  position:fixed;
  width:100%;
  height:100%;
  background:#fff;
  left:0;
  top:0;
  z-index:9999;
}

span.label {
  width:200px;
  padding-top:5px;
  float:left;
}

.order .total {
  font-size:24px;
  margin-top:10px;
  margin-bottom:20px;
}

.order input, .order select {
  min-width:200px;
}

.loading div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

span.save-status {
  float: right;
  margin-top: -57px;
  padding:7px;
  color:#fff;
  background:rgb(0, 189, 79);
  border-radius:3px;
}

span.save-status.saving {
  background:rgb(224, 146, 0);
}

.incomplete {
  border-style: solid;
  margin-top: 0px;
  margin-bottom: 10px;
  border-color: #ea2112ab;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 11px;
}

.incomplete_title {
  padding-bottom: 10px;
}

.tables .table {
  padding:15px;
  margin-bottom:15px;
}

.select_dropdown {
  margin-right:10px;
}

span.legend-circle {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  margin-right: 10px;
}

.table-border-left {
  border-left: 2px solid #fff;
}

.district-signup label {
  width: 130px;
  display: inline-block;
  margin-bottom:15px;
}

.school-signup {
  padding:20px;
  border-radius:5px;
  margin-bottom:20px;
  margin-top:20px;
  border:1px solid #eee;
}

.school-signup button {
  margin-top:10px;
}

.react-file-reader {
  display:inline-block;
  margin-left:10px;
}

.react-file-reader-button {
  display:inline-block;
}

.save-setup-schools-button {
  margin-top:20px !important;
}

.react-pricing-table button {
  display:none;
}

.global-message {
  color:#fff;
  padding:10px;
  text-align:center;  
  margin-bottom:30px;
}

.global-message.warn button {
  background: #fff;
  color:#b98903;
  border:0px;
  padding:5px;
  border-radius: 3px;
  margin:10px;
}

.global-message.warn button:hover {
  background:#f0f0f0;
  cursor:pointer;
}

.global-message.warn {
  background:#c466c5;
}


.global-message.success {
  background:rgb(18, 128, 18)
}

.signup {
  padding:30px;
  box-sizing: border-box;
  width:50%;
  background:none;
  border: 1px solid #eee;
  margin-bottom:30px;
  margin-right:-1px;
  font-size:20px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.signup.active {
  background:#2078b7;
  color:#fff;
}

.signup:hover {
  background:#0e5e97;
  color:#fff;
}

.report-samples img {
  border:1px solid #eee;
}

.report-request {
  float:right;
}

.manage-schools {
  padding:20px;
  border:1px solid #ccc;
  border-radius: 3px;
  margin-bottom:25px;
}

.manage-schools h4 {
  margin:0px;
}

.state-district-select select[name="state"] {
  width:20%;
}

.state-district-select select {
  width:38%;
}

.add-code-form input {
  margin-right:15px;
}

.school-signup-select select {
  max-width:240px;
}

.mobile {
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 75px;
}

.welcome-title-mobile h3 {
  color: #7ed957;
  font-size: 18px;
}

.welcome-message-mobile {
  font-size: 14px;
}

.question.text-mobile input {
  width: 90%;
  margin-top: 7px;
}

span.save-status-mobile {
  float: left;
  margin-top: -57px;
  padding: 7px;
  color: #fff;
  background: rgb(0, 189, 79);
  border-radius: 3px;
}

span.save-status-mobile.saving {
  background: rgb(224, 146, 0);
}

h3.mobile {
  color: #444;
  font-weight: 300;
  font-size: 20px;
  margin-top: 0px;
  padding-top: 0px;
  font-weight: bold;
}

.wrap
{
    width: 100%;
    max-height: 384px;
    padding: 0;
    overflow: hidden;
}

.frame
{
    width: 1280px;
    height: 786px;
    border: 0;

    -ms-transform: scale(0.25);
    -moz-transform: scale(0.25);
    -o-transform: scale(0.25);
    -webkit-transform: scale(0.25);
    transform: scale(0.25);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

.sub-question {
  margin-left: 40px;
}
